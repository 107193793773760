import React, { useEffect, useState } from 'react';
import useTokenRefresh from '../services/useTokenRefresh';
import { Table, notification, Button, Spin, Modal, Input } from 'antd';
import { useGetOrdersQuery, useCancelOrderMutation, useBuyLimitMutation, useBuyMarketMutation, useSellLimitMutation, useSellMarketMutation } from '../services/localApi';
import { RedoOutlined } from '@ant-design/icons';
import './common.css';

const Orders = () => {
  useTokenRefresh();
  const { data, isFetching, refetch, error } = useGetOrdersQuery();
  const [cancelOrder] = useCancelOrderMutation();
  const [buyLimit] = useBuyLimitMutation();
  const [buyMarket] = useBuyMarketMutation();
  const [sellLimit] = useSellLimitMutation();
  const [sellMarket] = useSellMarketMutation();
  const [BOrders, setBOrders] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderType, setOrderType] = useState('');
  const [symbol, setSymbol] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60000); // 60 seconds

    if (initialLoad && !isFetching && data) {
      setInitialLoad(false);
    }
    if (data) {
      if (data.B) setBOrders(data.B);
    }
    if (error && !isFetching) {
      notification.error({
        message: '2 Cannot load orders',
        description: `Login again !!`,
      });
      setBOrders([]);
    }

    return () => clearInterval(intervalId);
  }, [refetch, isFetching, initialLoad, data, error]);

  const handleCancelOrder = async (orderId, symbol) => {
    try {
      await cancelOrder({ orderId, symbol }).unwrap();
      notification.success({
        message: 'Order Cancelled',
        description: `Order ${orderId} for ${symbol} has been cancelled.`,
      });
      refetch();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: `Failed to cancel order ${orderId} for ${symbol}: ${JSON.stringify(err)}`,
      });
    }
  };

  const handleOrder = async () => {
    try {
      if (orderType === 'buy-limit') {
        await buyLimit({ symbol, price: price, quantity: quantity }).unwrap();
      } else if (orderType === 'buy-market') {
        await buyMarket({ symbol, quantity: quantity }).unwrap();
      } else if (orderType === 'sell-limit') {
        await sellLimit({ symbol, price: price, quantity: quantity }).unwrap();
      } else if (orderType === 'sell-market') {
        await sellMarket({ symbol, quantity: quantity }).unwrap();
      }
      notification.success({
        message: 'Order Placed',
        description: `Order ${orderType} for ${symbol} has been placed.`,
      });
      refetch();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: `Failed to place order ${orderType} for ${symbol}: ${JSON.stringify(err)}`,
      });
    }
    
    setIsModalVisible(false);
  };

  const showModal = (type) => {
    setOrderType(type);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    handleOrder();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: 'Wallet',
      dataIndex: 'wallet',
      sorter: (a, b) => a.wallet.localeCompare(b.wallet),
    },
    {
      title: 'Side',
      dataIndex: 'side',
      sorter: (a, b) => a.side.localeCompare(b.side),
    },
    {
      title: 'Order price',
      dataIndex: 'order_price',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Notional',
      dataIndex: 'notional',
      sorter: (a, b) => parseFloat(a.notional) - parseFloat(b.notional),
    },
    {
      title: '% change',
      dataIndex: 'percentage',
      sorter: (a, b) => parseFloat(a.percentage) - parseFloat(b.percentage),
      render: (value) => (
        <span style={{ color: Math.abs(value) < 0.1 ? 'red' : 'black' }}>
          {value}
        </span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'orderId',
      render: (orderId, record) => (
        <Button danger onClick={() => handleCancelOrder(orderId, record.symbol)}>
          Cancel
        </Button>
      ),
    },
  ];

  const handleRefreshButton = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  return (
    <div className="tables-container">
      <div style={{flex: '0.75'}}>
        <Button style={{ borderColor: 'green', fontWeight: 'bold', color: 'green'}} onClick={() => showModal('buy-limit')} > Buy limit </Button>
        <Button style={{ borderColor: 'green', fontWeight: 'bold', color: 'green', marginLeft:'10px' }} onClick={() => showModal('buy-market')} > Buy market </Button>
        <Button danger style={{marginLeft:'10px' }} onClick={() => showModal('sell-limit')} > Sell limit</Button>
        <Button danger style={{marginLeft:'10px' }} onClick={() => showModal('sell-market')} > Sell market</Button>
      </div>
      <div style={{ flex: '1', marginRight: '20px' }}>
        <h4>
          B Orders
          <Button type="primary" ghost shape="round" onClick={() => handleRefreshButton()} style={{'marginLeft': '20px'}} icon={<RedoOutlined />}> Refresh </Button>
        {(loading || isFetching) && (
            <Spin style={{ marginLeft: '10px' }} />
          )}
        </h4>
        <Table
          columns={columns}
          dataSource={BOrders}
          pagination={false}
          size="small"
          loading={isFetching}
        />
      </div>
      <Modal title="Place Order" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={600}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '120px' }}> Symbol : </div>
          <Input style={{ margin: '5px' }} placeholder="Symbol" value={symbol} onChange={(e) => setSymbol(e.target.value)} />          
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '120px' }}> Amount : </div>
          <Input style={{ margin: '5px' }} placeholder="Quantity" type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} /> 
        </div>
        {(orderType === 'buy-limit' || orderType === 'sell-limit') && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '120px' }}> Price : </div>
          <Input style={{ margin: '5px' }} placeholder="Price" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
        </div>
        )}
      </Modal>
    </div>
  );
};

export default Orders;

