// CheckAssetBalancesComponent.js
import React, { useState } from 'react';
import { Table, Button, Dropdown, Menu, notification } from 'antd';
import { useGetFrHistoryMutation, useGetEarnLoanAPYMutation } from '../services/localApi';
// import { earnLoanColumns, fundingRateColumns } from './common';
import './common.css';
import { showFrPopup } from './common';

const CheckFundingRateHistoryComponent = ({ onTopEarnListUpdate }) => {
  const [textBoxCheckFundingRate, setTextBoxCheckFundingRate] = useState('');
  const [getFrHistory] = useGetFrHistoryMutation();
  const [getEarnLoan] = useGetEarnLoanAPYMutation();
  const [frData, setFrData] = useState(null);
  const [earnLoanData, setEarnLoanData] = useState(null);

  const [earnLength, setEarnLength] = useState(() => {
    // Get saved value from localStorage or default to 20
    const savedValue = localStorage.getItem('earnLength');
    return savedValue !== null ? parseInt(savedValue) : 20;
  });
  
  const handleEarnLengthSelect = (e) => {
    const newValue = parseInt(e.key);
    localStorage.setItem('earnLength', newValue);
    setEarnLength(newValue);
    
  }
  
  const earnLengthsMenu = (
    <Menu onClick={handleEarnLengthSelect}>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="40">40</Menu.Item>
      <Menu.Item key="60">60</Menu.Item>
    </Menu>
  );

  const handleEarnLengthChange = (e) => {
    const newValue = parseInt(e.target.value);
    localStorage.setItem('earnLength', newValue);
    setEarnLength(newValue);
    
  };

  const handleCellClick = async (e, asset) => {
    if (asset==='earn' || asset==='borrow' || asset==='flex loan') {
      return;
    }
      // Prevent default behavior
      e.preventDefault();
      const res = await getFrHistory({ symbol: asset + "USDT", exchange: "H" }).unwrap();
      showFrPopup(e, asset + "USDT", "H", res.response, res.summary); // Show result at popup
  };
  
  const fundingRateColumns = [
    {
        title: 'Time',
        dataIndex: 'time',
    },
    {
        title: 'B',
        dataIndex: 'B',
    },
    {
        title: 'H',
        dataIndex: 'H',
    },
  ];

  const earnLoanColumns = [
    {
        title: 'Coin',
        dataIndex: 'asset',
        render: (text, record, index) => (
          <span 
              style={{ display: 'block', cursor: 'pointer'}} 
              onClick={(e) => handleCellClick(e, text)} >
              {text}
          </span>
      ),
    },
    {
        title: 'APY',
        dataIndex: 'latestAPR',
    },
  ];


  const handleButtonCheckFundingRate = async () => {
    setEarnLoanData(null);
    setFrData(null);
    // eslint-disable-next-line
    try {
      const res = await getFrHistory({ symbol: textBoxCheckFundingRate }).unwrap();
      setFrData(res);
    } catch (err) {
      console.error('Check FR error details:', err);
      notification.error({
        message: 'Check FR',
        description: `Error : ${JSON.stringify(err)}`,
      });
    }
  };

  const handleButtonGetEarnLoan = async () => {
    setEarnLoanData(null);
    setFrData(null);
    // eslint-disable-next-line
    try {
      const res = await getEarnLoan({ symbol: textBoxCheckFundingRate, length: earnLength }).unwrap();
      setEarnLoanData(res);
      const topEarnList = res.response.map(item => item.asset + "USDT");
      console.log('Assets List:', topEarnList);
      onTopEarnListUpdate(topEarnList); // Pass the assets list to the parent component
    } catch (err) {
      console.error('Get APY error details:', err);
      notification.error({
        message: 'Get APY',
        description: `Error : ${JSON.stringify(err)}`,
      });
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '10px', position: 'relative' }}>
        <div style={{ width: '100px' }}>FR / Earn  : </div>
        <input style={{ marginLeft: '5px', width: '150px'}} type="text" value={textBoxCheckFundingRate} onChange={(e) => { setTextBoxCheckFundingRate(e.target.value); }} />
        <div style={{ position: 'absolute', right: '0' }}>
          n = 
          <Dropdown overlay={earnLengthsMenu}>
            <Button style={{ marginLeft: '5px', marginRight: '5px' }} >
              {earnLength}
            </Button>
          </Dropdown>
          <Button className="normal-button" style={{width:'60px'}} onClick={handleButtonGetEarnLoan}>APY</Button>
          <Button className="normal-button" style={{ marginLeft: '5px' }} type="primary" onClick={handleButtonCheckFundingRate}> Get fr  </Button>
        </div>

      </div>
      {frData
              && (
              <div>
                <div style={{ fontSize: '15px' }}>{ frData.description } </div>
                <Table
                  columns={fundingRateColumns}
                  dataSource={frData.response}
                  size="small"
                  style={{ marginTop: '20px' }}
                  pagination={false}
                />
                <div style={{ fontSize: '15px' }}>{ frData.summary } </div>
              </div>
)}

      {earnLoanData
              && (
              <div>
                <div style={{ fontSize: '15px' }}>
                  {earnLoanData.description}
                </div>
                <Table
                  columns={earnLoanColumns}
                  dataSource={earnLoanData.response}
                  size="small"
                  style={{ marginTop: '20px' }}
                  pagination={false}
                />

              </div>
)}

    </div>
  );
};

export default CheckFundingRateHistoryComponent;
