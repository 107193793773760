import React, { useState } from 'react';
import {Button, notification } from 'antd';
import { useRestartServerMutation } from '../services/localApi';
const Setting = () => {
  
const [restartServer] = useRestartServerMutation();

const handleLogoutButton = () => {
  console.log('log out')
  localStorage.setItem('isLoggedIn', 'false');
  document.cookie = "user_id=; path=/;"; 
  document.cookie = "access_token=; path=/;"; 
  notification.success({
    message: 'Success',
    description: `Logout success !`,
  });
};

  const handleRestartButton = () => {
    
    try {
      restartServer({ user: localStorage.getItem('access_token')}).unwrap();
      notification.success({
        message: 'Success',
        description: `Restart success !`,
      });
    } catch (err) {
      notification.error({
        message: 'Error',
        description: `error : ${err?.data}`,
      });
    }
  };
  return (
    <div>
      <div>
        <Button className="normal-button" onClick={handleRestartButton}> Restart ws </Button>
        <Button className="normal-button" onClick={handleLogoutButton}> Logout </Button>
      </div>
     
      {/* {serviceResponse && (
        <div>{serviceResponse}</div>
      )} */}
    </div>
  );
};

export default Setting;
