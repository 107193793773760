export const showFrPopup = (e, symbol, columnName, data, position) => {
  const scrollY = window.scrollY; // Get current vertical scroll position
  const x = e.clientX; // Mouse x position
  const y = e.clientY + scrollY; // Mouse y position plus scroll offset

  const popup = document.createElement('div');
  popup.style.position = 'absolute';
  popup.style.left = `${x}px`;
  popup.style.top = `${y}px`; // Set the top position using the adjusted y
  popup.style.border = '1px solid #ccc';
  popup.style.backgroundColor = 'white';
  popup.style.padding = '15px'; // Increased padding
  popup.style.zIndex = '1000';
  popup.style.maxHeight = '400px'; // Increased max height
  popup.style.overflowY = 'auto'; // Allow scrolling if content is too long
  popup.style.boxShadow = '0 2px 10px rgba(0, 0, 0, 0.1)'; // Add a subtle shadow for better visibility

  const positionDiv = document.createElement('div');
  
  if ( position !== '' && position > 0) {
    positionDiv.innerText = `${columnName} position:    ${position} $`; // Set the text to show the position
    positionDiv.style.fontWeight = 'bold'; // Make the text bold
    positionDiv.style.fontSize = '15px'; // Make the text bold
    positionDiv.style.color = 'green'; // Add some space below the position text
  } 
  else if ( position !== '' && position < 0) {
    positionDiv.innerText = `${columnName} position:    ${position} $`; // Set the text to show the position
    positionDiv.style.fontWeight = 'bold'; // Make the text bold
    positionDiv.style.fontSize = '15px'; // Make the text bold
    positionDiv.style.color = 'red'; // Add some space below the position text
  } 
  else {
    positionDiv.innerText = `No ${columnName} position`; // Set the text to show the position
  }

// // Create a wrapper for buttons
// const buttonWrapper = document.createElement('div');
// buttonWrapper.style.display = 'flex';
// buttonWrapper.style.flexDirection = 'row'; // Align buttons vertically
// buttonWrapper.style.alignItems = 'flex-start'; // Align buttons to the start (left)

// // Create Buy button
// const buyButton = document.createElement('button');
// buyButton.innerText = 'Long';
// buyButton.style.color = 'green'; // Set text color
// buyButton.style.border = '2px solid green'; // Set border color
// buyButton.style.padding = '5px'; // Add vertical spacing between buttons

// // Add onClick function for Buy button
// buyButton.onclick = () => {
//     // Your logic for Buy action
//     alert('Buy button clicked!');
// };

// // Create Sell button
// const sellButton = document.createElement('button');
// sellButton.innerText = 'Short';
// sellButton.style.color = 'red'; // Set text color
// sellButton.style.border = '2px solid red'; // Set border color
// buyButton.style.padding = '5px 5px 5px 20px'; // Add vertical spacing between buttons

// // Add onClick function for Sell button
// sellButton.onclick = () => {
//     // Your logic for Sell action
//     alert('Sell button clicked!');
// };
// // Append buttons to the wrapper
// buttonWrapper.appendChild(buyButton);
// buttonWrapper.appendChild(sellButton);

  // Create a table to display the data
  const table = document.createElement('table');
  table.style.width = '100%'; // Make the table full width
  table.style.borderCollapse = 'collapse'; // Collapse borders for a cleaner look
  table.style.borderSpacing = '10px'; // Optionally, apply spacing between table cells

  // Create the header row
  const header = table.insertRow();
  const cell1 = header.insertCell();
  cell1.innerHTML = `<strong>${symbol}</strong>`;
  cell1.style.padding = '5px'; // Add padding to header cells
  cell1.style.borderBottom = '2px solid #ccc'; // Separate header from body
  const cell2 = header.insertCell();
  cell2.innerHTML = `<strong>${columnName}</strong>`;
//   cell2.style.padding = '10px 10px 10px 20px'; // Add left padding to create space
  cell2.style.padding = '5px 5px 5px 10px'; // Add padding to body cells
  cell2.style.borderBottom = '2px solid #ccc'; // Separate header from body
  
  // Fill the table with data
  data.forEach(item => {
      const row = table.insertRow();
      const cell1 = row.insertCell();
      cell1.innerText = item.time;
      cell1.style.padding = '5px'; // Add padding to body cells
      const cell2 = row.insertCell();
      cell2.innerText = item.fr.toFixed(4);
      cell2.style.padding = '5px 5px 5px 20px'; // Add padding to body cells
      cell2.style.textAlign = 'right'; // Align "H" to the right for better readability
  });

  popup.appendChild(positionDiv);
//   popup.appendChild(buttonWrapper); // Append the wrapper instead of the buttons directly
  popup.appendChild(table);
  document.body.appendChild(popup);

  // Optional: Remove the popup on click anywhere
  const removePopup = () => {
      popup.remove();
      document.removeEventListener('click', removePopup);
  };

  document.addEventListener('click', removePopup);
};

export const checkAssetColumns = [
    {
        title: 'S',
        dataIndex: 's',
    },
    {
        title: 'SPOT',
        dataIndex: 'spot',
    },
    {
        title: 'ISO',
        dataIndex: 'iso',
    },
    {
        title: 'CROSS',
        dataIndex: 'cross',
    },
    {
        title: 'PERP',
        dataIndex: 'perp',
    },
    {
        title: 'EARN',
        dataIndex: 'earn',
    },
];

export const checkSymbolColumns = [
    {
        title: 'Pair',
        dataIndex: 's',
    },
    {
        title: 'price',
        dataIndex: 'price',
    },
    {
        title: 'SHORT',
        dataIndex: 'short',
    },
    {
        title: 'LONG',
        dataIndex: 'long',
    },
    {
        title: 'POS',
        dataIndex: 'position',
    },
    {
        title: 'FR',
        dataIndex: 'fr',
    },
];
