import { useEffect } from 'react';
import { useRefreshCookieQuery } from './localApi';
import { notification } from 'antd';
const useTokenRefresh = () => {
  // const {data, isFetching, refetch, error} = useRefreshCookieQuery();
  // useEffect(() => {
  //   // Set interval to reload assets every 30 seconds
  //   const interval = setInterval(() => {
  //     refetch();
  //   }, 120000); // TODO change refresh interval to 3min


  //   if (error) {
  //     notification.error({
  //       message: 'Cannot refresh access token ',
  //       description: `Login again !!`,
  //     });
  //   }
  //   // Cleanup interval on component unmount
  //   return () => clearInterval(interval);
  // }, [refetch]); // Add getAssets to dependencies if necessary

};

export default useTokenRefresh;
