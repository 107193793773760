import React, { useState } from 'react';
import { Modal, Button, Input, Radio, notification } from 'antd';
import { usePostTradeMutation } from '../services/localApi';
import TradeModal from './TradeModal'; // Import the newly created TradeModal component

const PostTradeComponent = () => {
  const [postTrade] = usePostTradeMutation();
  const [isVisible, setIsVisible] = useState(false);
  const [isolatedVisible, setIsolatedVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [symbol, setSymbol] = useState('');
  const [amountUSD, setAmountUSD] = useState('');
  const [isolated, setIsolated] = useState(false);
  const [bidask, setBidask] = useState('');

  const handleClick = (title) => {
    setDialogTitle(title);
    setIsVisible(true);
    setIsolatedVisible(["AB_Long", "AB_Short", "AH_Long", "AH_Short"].includes(title));
  };

  const handleOk = async () => {
    setIsVisible(false); // Close the dialog
    try {
      await postTrade({ side: dialogTitle, symbol, amountUSD, isolated, bidask }).unwrap(); // Using unwrap to get the response directly
      // Check if postTrade was successful
      notification.success({
        message: 'Success',
        description: `Post ${ dialogTitle } successfully.`,
      });
    } catch (err) {
      // Handle error here
      console.error('Post trade error details:', err);
      notification.error({
        message: 'Post trade',
        description: `Error : ${JSON.stringify(err)}`,
      })
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
  };


  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px' }}>
        <div style={{ width: '50px' }}> Short : </div>
        <div>
        <Button danger onClick={() => handleClick('AB_Short')} style={{ fontWeight: 'bold', marginLeft:'10px' }}> AB </Button>
        <Button danger onClick={() => handleClick('AH_Short')} style={{ fontWeight: 'bold', marginLeft:'10px' }}> AH </Button>
        <Button danger onClick={() => handleClick('BG_Short')} style={{ fontWeight: 'bold', marginLeft:'10px' }}> BG </Button>
        <Button danger onClick={() => handleClick('BH_Short')} style={{ fontWeight: 'bold', marginLeft:'10px' }}> BH </Button>
        <Button danger onClick={() => handleClick('GH_Short')} style={{ fontWeight: 'bold', marginLeft:'10px' }}> GH </Button>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop:'5px' }}>
        <div style={{ width: '50px' }}> Long : </div>
        <div>
        <Button onClick={() => handleClick('AB_Long')} style={{ borderColor: 'green', fontWeight: 'bold', color: 'green', marginLeft:'10px'  }}> AB </Button>
        <Button onClick={() => handleClick('AH_Long')} style={{ borderColor: 'green', fontWeight: 'bold', color: 'green', marginLeft:'10px'  }}> AH </Button>
        <Button onClick={() => handleClick('BG_Long')} style={{ borderColor: 'green', fontWeight: 'bold', color: 'green', marginLeft:'10px'  }}> BG </Button>
        <Button onClick={() => handleClick('BH_Long')} style={{ borderColor: 'green', fontWeight: 'bold', color: 'green', marginLeft:'10px'  }}> BH </Button>
        <Button onClick={() => handleClick('GH_Long')} style={{ borderColor: 'green', fontWeight: 'bold', color: 'green', marginLeft:'10px'  }}> GH </Button>
        </div>
      </div>
    <TradeModal
      visible={isVisible}
      title={dialogTitle}
      onOk={handleOk}
      onCancel={handleCancel}
      symbol={symbol}
      setSymbol={setSymbol}
      amountUSD={amountUSD}
      setAmountUSD={setAmountUSD}
      bidask={bidask}
      setBidask={setBidask}
      isolatedVisible={isolatedVisible}
      isolated={isolated}
      onChangeIsolated={(e) => setIsolated(e.target.value)}
    />
    </div>
  );
};

export default PostTradeComponent;
